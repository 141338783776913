import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Adam from "../media/mediaCompanies/Adam.jpg";
import Anne from "../media/mediaCompanies/Anne.png";
import Ben from "../media/mediaCompanies/Ben.jpg";
import Jen from "../media/mediaCompanies/Jen.jpg";
import Max from "../media/mediaCompanies/Max.jpg";
import Timo from "../media/mediaCompanies/Timo.png";

import "../style/companiesHomepage.css"

const CompaniesTestimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div className="testimonials-container">
        <div class="custom-shape-divider-top-1718802291-2">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <h1>Become an organization of the Future.</h1>
        <Slider {...settings}>
          <div className="testimonial">
            <img src={Max} alt="image1" />
            <h3>
              "Aula Future's challenges offer future generations the
              
              opportunity to build a sustainable world. Aula is transforming
            
              the industry by offering problem-solving initiatives that foster
              
              success for changemakers and organizations alike."
            </h3>
            <p>Max, Keel Labs</p>
          </div>
          <div className="testimonial">
            <img src={Timo} alt="image1" />
            <h3>
              "We are confident in calling this partnership a success due to
             
              the achieved community engagement and the innovation
              
              lab's design approach, which opens new perspectives on
             
              today's business through an explorative and speculative lens."
            </h3>
            <p>Timo, H&M beyond.</p>
          </div>
          <div className="testimonial">
            <img src={Ben} alt="image1" />
            <h3>
              "The instructors fully invest themselves into projects. They dive
              
              in deep, thinking about the message and how to
             
              communicate design concepts in a unique, personal way.
              
              Moreover, they give back as much as they learn, ensuring
             
              everyone around them grows at the same time."
            </h3>
            <p>Ben, Integral Molecular</p>
          </div>
          <div className="testimonial">
            <img src={Anne} alt="image1" />
            <h3>
              "During the Aula Future program, we built and sustained a
              habitat for co-working and co-creation. Throughout this joint
            
              venture, the team was very focused and passionate about
              
              their contribution and beyond."
            </h3>
            <p>Anne, H&M beyond.</p>
          </div>
          <div className="testimonial">
            <img src={Jen} alt="image1" />
            <h3>
              "The team members at Aula Future are terrific collaborators
              
              who seek to understand and design for the desired outcome.
              
              We had excellent engagement and feedback throughout the
             
              speculative design series. They are truly a pleasure to work
              
              with."
            </h3>
            <p>Jen, Science Center</p>
          </div>
          <div className="testimonial">
            <img src={Adam} alt="image1" />
            <h3>
              "Aula Future has been essential to our programming
            
              expansion and implementation. Without them, we wouldn’t be
             
              able to offer our students such in-depth programming in
             
              Biodesign, Design Thinking, and speculative design."
            </h3>
            <p>Adam, FirstHand</p>
          </div>

          {/* Add more <div> elements for additional images */}
        </Slider>
      </div>
    </>
  );
};
export default CompaniesTestimonials;
