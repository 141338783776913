import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Routes } from "react-router-dom";
import UsersHomepage from "./pages/UsersHomepage";
import CompaniesHomepage from "./pages/CompaniesHomepage";
import Navbar from "./components/Navbar";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import Impressum from "./pages/Impressum";
import Team from "./pages/Team";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<UsersHomepage />} />
        <Route path="/companies" element={<CompaniesHomepage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/team" element={<Team />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
