import logo from "../media/navbar-logo.png";
import "../style/navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="navbar-links">
          <Link to="/">For Learners</Link>
          <Link to="/companies">For Companies</Link>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
