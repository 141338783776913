import Gen from "../media/gen.png";
import Marco from "../media/marco.jpg";
import Marina from "../media/marina.jpg";
import Joao from "../media/joao.png";
import "../style/team.css";
import SecondaryBtn from "../components/SecondaryBtn";

const Team = () => {
  return (
    <>
      <div className="team-container">
        <div className="member-container">
          <div className="member-info">
            <h2>Genefer Baxter</h2>
            <h3>Founder, Chief Marketing Officer</h3>
            <p id="member-description">
              Genefer is professionally trained in Design, Business, and
              Marketing. After graduating from the University of Hawai'i at
              Manoa, she continued her self-directed studies in biodesign,
              speculative design, industrial design, and graphic design, an
              experience that has given her a firsthand look into design
              education and business. Genefer has also earned several
              certificates for her training in regenerative design, systems
              thinking, and design thinking, and continues to broaden her
              philosophy and approach to design and sustainability.
            </p>
            {/*  <a
              href="https://www.linkedin.com/in/genefer-baxter/"
              target="_blank"
              className="contact-link"
            >
              Connect with Genefer
            </a> */}
            <SecondaryBtn
              text="Connect with Genefer"
              navigateTo="https://www.linkedin.com/in/genefer-baxter/"
            />
          </div>
          <div>
            <img src={Gen} className="member-photo" />
          </div>
        </div>
        <div className="member-container">
          <div className="member-info">
            <h2>Marco Locatelli</h2>
            <h3>Founder, Chief Product Officer</h3>
            <p id="member-description">
              Marco Locatelli is an Italian designer based in Berlin. Marco
              received formal training in product design, material engineering,
              and nanotechnology at the Polytechnic University of Milan. Since
              then, he has become a Biodesign Champion Instructor and furthered
              his education as a software developer. Marco is passionate about
              and is motivated to tackle the issue of sustainable innovation.
            </p>
            {/*  <a
              href="https://www.linkedin.com/in/marcolocatellibg/"
              target="_blank"
              className="contact-link"
            >
              Connect with Marco
            </a> */}
            <SecondaryBtn
              text="Connect with Marco"
              navigateTo="https://www.linkedin.com/in/marcolocatellibg/"
            />
          </div>
          <div>
            <img src={Marco} className="member-photo" />
          </div>
        </div>
        <div className="member-container">
          {" "}
          <div className="member-info">
            <h2>Marina Muñoz</h2>
            <h3>Software Developer</h3>
            <p id="member-description">
              Marina is a Spanish developer based in Berlin, who initially
              pursued a degree in languages at the University of Aberdeen.
              Later, she transitioned to a tech career by enrolling in a boot
              camp program to become a proficient full-stack developer.
              Continuously dedicated to advancing her technical expertise,
              Marina is actively engaged in refining her skills. In her free
              time, she loves playing chess!
            </p>
            {/*   <a
              href="https://www.linkedin.com/in/marinamun/"
              className="contact-link"
              target="_blank"
            >
              Connect with Marina
            </a> */}
            <SecondaryBtn
              text="Connect with Marina"
              navigateTo="https://www.linkedin.com/in/marinamun/"
            />
          </div>
          <div>
            <img src={Marina} className="member-photo" />
          </div>
        </div>
        <div className="member-container">
          {" "}
          <div className="member-info">
            <h2>João (Althis) Lucas Mendes</h2>
            <h3>CTO</h3>
            <p id="member-description">
              "Technology is only worth anything if it is used for the good of
              people" has always been the mantra of Althis. Graduating with a
              Computer Science degree from UFPE in 2020, he moved to Germany to
              get his Master's in Cognitive Systems by TU Berlin. In the
              meantime he has held leading positions in many projects, such as
              in leading digital transformation for corporations and developing
              state of the art conversational agents for research institutes.
              Now Althis sets his sights on a way to use tech for good in a
              different way, by facilitating companies transition to the Green
              Economy with Aula Future.
            </p>
            {/*   <a
              href="linkedin.com/in/joao-lucas-mendes
"
              target="_blank"
              className="contact-link"
            >
              Connect with João
            </a> */}
            <SecondaryBtn
              text="Connect with João"
              navigateTo="linkedin.com/in/joao-lucas-mendes
"
            />
          </div>
          <div>
            {" "}
            <img src={Joao} className="member-photo" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Team;
