import "../style/submitBtn.css";

const SubmitBtn = ({ text, form }) => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Destructure form data
    const { firstName, lastName, email, message } = form;

    // Send POST request to server to send their message as an email
    try {
      const response = await fetch("/contact-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, email, message }),
      });

      if (response.ok) {
        console.log(
          "Request successfully handled by the server, not sure if the email reached its destination"
        );
      } else {
        console.log(
          "The server was not able to process the post request and send the email😭"
        );
      }
    } catch (error) {
      console.error("Error sending email:", error.message);
    }
  };

  return (
    <>
      <button type="submit" className="main-btn" onClick={handleSubmit}>
        {text}
      </button>
    </>
  );
};

export default SubmitBtn;
