import HeaderImage from "../media/mediaUsers/usersHeaderImage.png";
import Icon from "../media/mediaUsers/userIcon.png";
import Icon2 from "../media/mediaUsers/userIcon2.png";
import Icon3 from "../media/mediaUsers/userIcon3.png";
import Icon4 from "../media/mediaUsers/userIcon4.png";
import Icon5 from "../media/mediaUsers/userIcon6.png";
import Icon6 from "../media/mediaUsers/userIcon5.png";
import Icon7 from "../media/mediaUsers/userIcon7.png";
import UsersTestimonials from '../components/UsersTestimonials';
import "../style/usersHomepage.css";
import PrimaryBtn from '../components/PrimaryBtn';
import Circular from "../media/mediaUsers/circularDesign.png";
import Bio from "../media/mediaUsers/biomaterials.png";
import ContainerBtn from '../components/ContainerBtn';
import Learner from "../media/mediaUsers/learner.png";
import Credits from "../components/Credits";

function UsersHomepage() {
  return (
    <>
    
        <div className="header">
          <div className="header-text">
            <h2>
            Real-world change,
            <br />
            one sustainable 
            <br />
            project at a time.
            </h2>
           <p>
            Learn to create sustainable solutions by participating in innovation Sprints from industry leaders.
           </p>
           <button className="main-btn">Explore Sprints</button>
          </div>
          <div className="header-image">
          <img src={HeaderImage}/>
          </div>
        </div>

        <div className="userpanel2">
          <div className="userpanel2-group">
          <img src={Icon} />
          <p>
              Learn about cutting-edge topics in Sustainability
          </p>
          </div>
          <div className="userpanel2-group">
            <img src={Icon2} />
            <p>
               Develop unique solutions to real-world challenges
            </p>
          </div>
          <div className="userpanel2-group">
            <img src={Icon3}/>
          <p>
              Build a strong network of peers and professionals
          </p>
          </div>
        </div>

        <div className="userpanel3">
          <h3>
            Gain real world Experience tackling Sustainability Challenges
          </h3>
          <div className="video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/wIuzygKJOfU?si=OCEozn4u49bBq56f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="video-text">
            <p className="paragraph">
            Not getting enough real -life 
            experience? 
            </p>
            <p className="paragraph1">
              Traditioal e-courses often fall short.They
              teach outdated information, tools, and 
              frameworks, which can lead to a lack of 
              relevant skills necessary to kickstart your 
            </p>
            <p className="paragraph2">
            Whether you’re a complete beginner or a professional, Aula Future helps you learn 
            about cutting-edge topics, access tools and frameworks, and apply what you’ve learned 
            to tackle real-world Sustainability 
            Challenges faced by industry leaders.
            </p>
          </div>
        </div>

        <div className="userpanel4">
          <h3>
            An all in one platform to learn, connect, and Innovate
          </h3>
          <div className="first">
            <div className="icon1-container">
            <img src ={Icon4} alt="" className="icon1" />
            </div>
            <div className="icon1-text">
              <p className="mini-header">
                Brideg Theory and Practice
              </p>
              <p className="paragraph1">
              Apply your knowledge to real-world projects and access a vast range of innovation sprints in topics like food systems, biomaterials, fashion, architecture, mobility, and more.
              </p>
            </div>
          </div>
          <div className="first">
            <div className="icon1-text">
              <p className="mini-header">
              Access quality education anytime, anywhere.
              </p>
              <p className="paragraph1">
              Receive high-quality education tailored to your needs, with expert-led instruction and quality education on cutting-edge topics in Green Tech and Sustainability.
              </p>
            </div>
            <div className="icon1-container" >
              <img src={Icon5} alt="" className="icon"/>
            </div>
          </div>
          <div className="first">
            <div className="icon1-container">
            <img src ={Icon6} alt="" className="icon" />
            </div>
            <div className="icon1-text">
              <p className="mini-header">
                Create innovative projects, effortlessly.
              </p>
              <p className="paragraph1">
              Utilize our intuitive templates and frameworks within our platform, making it a breeze to generate exciting project ideas. With user-friendly tools at your fingertips, you can unleash your creativity.
              </p>
            </div>
          </div>
          <div className="first">
            <div className="icon1-text">
              <p className="mini-header">
              Access quality education anytime, anywhere.
              </p>
              <p className="paragraph1">
              Receive high-quality education tailored to your needs, with expert-led instruction and quality education on cutting-edge topics in Green Tech and Sustainability.
              </p>
            </div>
            <div className="icon1-container" >
              <img src={Icon7} alt="" className="icon"/>
            </div>
          </div>
        </div>
        <div>
          < UsersTestimonials/>
        </div>
        <div className="userpanel6">
            <h3>
            Take a look at their sustainable innovations.
            </h3>
            
              <PrimaryBtn
                text= "Explore past projects"
                navigateTo = "/projects"
              />
        </div>
        <div className="custom-shape-divider-top-1718803736-3">
          <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
          </svg>
        </div>
        <div className="userpanel7">
          <h2>
          Explore Innovation Sprints.
          </h2>
          <p>
          All of our Sprints are crafted with the help of experts, researchers, and professionals
          <br /> 
           working in the respective industries.
          </p>
        </div>
        <div className="userpanel8">
          <div className="userpanel8-box">
            <img src={Circular} alt=""  />
            <div>
            <h3>
            Circular Design
            <br /> 
            Sprint
            </h3>
            <p>
            During this free sprint, you’ll be introduced to circular design principles and approaches.
            </p>
            <ContainerBtn
             text="Learn more"
             navigateTo="/courses make-it-loop-circular-design-challenge-a-framework/courses/make-it-loop-circular-design-challenge-a-framework"
              />
            </div>
          </div>
          <div className="userpanel8-box">
            <img src={Bio} alt="" />
            <div>
              <h3>
              Biomaterials
              <br /> 
              Design Sprint
              </h3>
              <p>
              Develop and pitch your own sustainable project proposal to earn a cash prize!
              </p>
              <ContainerBtn
               text="Learn more"
               navigateTo="/courses/biomaterials-sprint"
              />
            </div>
          </div>
        </div>
        <div className="userpanel9">
          <img src={Learner} alt="" />
          <div className="container9">
            <h3>
            Become a Changemaker, Today!
            </h3>
            <PrimaryBtn
            text="Explore more sprints"
            navigateTo="https://challenges.aulafuture.com/"
            />
          </div>
        </div>
        <div className="userpanel10">
          <Credits />
        </div>
    </>
  )
}

export default UsersHomepage
