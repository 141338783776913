import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function GenericCarousel(props) {
    if (props.settings === undefined){
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            standardWidth: '200px',
          };
    }else{
        var settings = props.settings;
    }
    var images = null;
    if (props.images === undefined){
        images = null;
    }else{
        images = props.images.map((image, index) => {
            return (
                <div>
                    <img src={image} alt={`image${index}`} style={{ width: settings.standardWidth }} />
                </div>
            );
        });
    }

  return (
    <>
      <Slider {...settings}>
        {images}
      </Slider>
    </>
  );
};
export default GenericCarousel;
