import { useNavigate } from "react-router-dom";
import "../style/secondaryBtn.css";
const SecondaryBtn = ({ text, navigateTo }) => {
  const navigate = useNavigate();

  //If the user wants to go to another page of our website, use navigate. If they want to go outside, use href.
  const handleClick = () => {
    if (navigateTo.startsWith("http")) {
      window.location.href = navigateTo; //external link
    } else {
      navigate(navigateTo); //internal link
    }
  };
  return (
    <>
      <button type="submit" className="secondary-btn" onClick={handleClick}>
        {text}
      </button>
    </>
  );
};

export default SecondaryBtn;
