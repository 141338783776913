import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Kira from "../media/mediaUsers/kira.png";
import Carla from "../media/mediaUsers/carla.jpg";
import Divya from "../media/mediaUsers/divya.jpg";
import Tino from "../media/mediaUsers/Tino.jpg";
import Josephine from "../media/mediaUsers/josephine.png";
import Gina from "../media/mediaUsers/Gina.png";


const UsersTestimonials = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <div className="testimonials-container">
        <p className="testi-paragraph">See what participants are saying.</p>
        < Slider {...settings}>
          <div className="testimonials">
            <img src={Kira} alt="image1" />
            <p className="testimonials-text">
              "The video learning classes and interactive 
              elements were not only engaging but also highly 
              educational for someone who did not have any
              experience in the circularity field"
            </p>
            <p className="testimonials-names">Kira</p>
          </div>
          <div className="testimonials">
            <img src={Carla} alt="image1" />
            <p className="testimonials-text"> 
              "Enriching and well-structured, this course fulfilled both my theoretical thirst and my desire to practically create a business project from scratch based on circular economy principles Aula future and its wonderful team will always be a reference point forme"
            </p>
            <p className="testimonials-names">Carla</p>
          </div>
          <div className="testimonials">
            <img src={Divya} alt="image1" />
            <p className="testimonials-text">
              "Each video in the course not only provided food 
              for thought, but also inspired us to think beyond our  
              Moreover, they give back as much as they learn, ensuring
              immediate career fields and adopt a multidisciplinary perspective."
            </p>
            <p className="testimonials-names">Divya</p>
          </div>
          <div className="testimonials">
            <img src={Tino} alt="image1" />
            <p className="testimonials-text">
              "I felt that I was part of a vibrant community of
              peers and mentors with similar passions who were 
              all working together towards common goals"
            </p>
            <p className="testimonials-names">Tino</p>
          </div>
          <div className="testimonials">
            <img src={Josephine} alt="image1" />
            <p className="testimonials-text">
              "As a young person, being able to have my creative processesflow and think outside the box means everything. This program had me value the delicate balance our ecoosystem crave, and inspired me to  do more on a lager scale."
            </p>
            <p className="testimonials-names">Josephine</p>
          </div>
          <div className="testimonials">
            <img src={Gina} alt="image1" />
            <p className="testimonials-text">
              "Aula Future has exposed me to so many new
               experiences all with hands on activities,
              which isperfect if you are a visual learner."
            </p>
            <p className="testimonials-names">Adam, FirstHand</p>
          </div>
        </Slider>
    </div>
  </>
   
  )
}

export default UsersTestimonials;
