import "../style/contactPage.css";
import ContactUsForm from "../components/ContactUsForm";

const ContactPage = () => {
  return (
    <>
      <div className="contact-page-container">
        <div className="contact-left-panel">
          <h1>Contact us.</h1>
          <p>
            hello@aulafuture.org
            <br />
            <i>Mon-Fri 10:00-19:00 (CET), weekends closed</i>
            <br />
            Berlin, Mitte
            <br />
            10117
            <br />
            Are you a <strong>company or institution?</strong>
            <br />
            Send us an inquiry, and schedule a free consultation!
          </p>
          <div className="contact-socials">
            <a
              href="https://www.linkedin.com/company/aulafuture"
              target="_blank"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCfJQgNCPSGJEuP_K0QekTzw"
              target="_blank"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/aula.future/" target="_blank">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
        </div>

        <div>
          <ContactUsForm />
        </div>
      </div>
    </>
  );
};
export default ContactPage;
