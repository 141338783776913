import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <>
      <h1>Login pageeee</h1>
      <form className="login-form">
        <input type="email" placeholder="Enter your email" />
        <input type="password" placeholder="Enter your password" />
        <div>
          <button>Google</button>
          <Link to="/signup">Sign up</Link>
        </div>
      </form>
    </>
  );
};
export default LoginPage;
