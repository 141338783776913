const SignupPage = () => {
  return (
    <>
      <h1> Sign upppp </h1>
      <form className="signup-form">
        <input type="email" placeholder="Enter your email"/>
        <input type="password" />
      </form>
    </>
  );
};
export default SignupPage;
