import "../style/impressum.css";
import SecondaryBtn from "../components/SecondaryBtn";
const Impressum = () => {
  return (
    <>
      <h1>IMPRESSUM</h1>
      <div className="impressum-container">
        <div className="impressum-column1">
          <h2>MARCO LOCATELLI</h2>
          <p>
            <span className="labels">Email:</span>
            <br></br> marco@aulafuture.org
          </p>

          {/* <a
            href="https://www.linkedin.com/in/marcolocatellibg/"
            target="_blank"
            className="secondary-btns"
          >
            Connect with Marco
          </a> */}
          <SecondaryBtn
            text="Connect with Marco"
            navigateTo="https://www.linkedin.com/in/marcolocatellibg/"
          />
        </div>
        <div className="impressum-column2">
          <h2>GENEFER BAXTER</h2>
          <p>
            <span className="labels">Email:</span> <br></br>{" "}
            genefer@aulafuture.org
          </p>

          {/*  <a
            href="https://www.linkedin.com/in/genefer-baxter/"
            target="_blank"
            className="secondary-btns"
          >
            Connect with Genefer
          </a> */}
          <SecondaryBtn
            text="Connect with Genefer"
            navigateTo="https://www.linkedin.com/in/genefer-baxter/"
          />
        </div>
        <div className="impressum-column3">
          <p>
            <span className="labels">
              Address:<br></br>
            </span>{" "}
            Luisenstraße 53, 10117 Berlin
          </p>
        </div>
      </div>
    </>
  );
};

export default Impressum;
