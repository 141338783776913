import "../style/footer.css";
import Impressum from "../pages/Impressum";
import Team from "../pages/Team";
import { Link } from "react-router-dom";
import PrimaryBtn from "./PrimaryBtn";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-left-panel">
          <h1>Aula Future</h1>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/aulafuture"
              target="_blank"
            >
              <i
                className="fa-brands fa-linkedin-in"
                id="footer-social-icon"
              ></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCfJQgNCPSGJEuP_K0QekTzw"
              target="_blank"
            >
              <i className="fa-brands fa-youtube" id="footer-social-icon"></i>
            </a>
            <a href="https://www.instagram.com/aula.future/" target="_blank">
              <i className="fa-brands fa-instagram" id="footer-social-icon"></i>
            </a>
          </div>

          {/* <Link to="contact-us" className="footer-contact-btn" target="_blank">
            Contact us
          </Link> */}
          <PrimaryBtn text="Contact Us" navigateTo="/contact-us" />
        </div>

        <div className="footer-right-panel">
          <h3>About us</h3>
          <Link to="/team" className="footer-link">
            Team
          </Link>

          <Link to="/impressum" className="footer-link">
            Impressum
          </Link>
          <a
            className="footer-link"
            href="https://www.aulafuture.com/newsletter-sign-up"
            target="_blank"
          >
            Subscribe to Newsletter
          </a>
          <p>
            <i>All rights reserved</i>
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
