import React from "react";
import GenericCarousel from "./GenericCarousel";
/* import image1 from "./media/carousel1.png"; */
import image1 from "../media/mediaCompanies/carousel1.png";	
import image2 from "../media/mediaCompanies/carousel2.png";
import image3 from "../media/mediaCompanies/carousel3.png";
import image4 from "../media/mediaCompanies/carousel4.png";
import image5 from "../media/mediaCompanies/carousel5.png";
import image6 from "../media/mediaCompanies/carousel6.png";
import image7 from "../media/mediaCompanies/carousel7.png";

const CompaniesCarousel = () => {
  const images = [image1, image2, image3, image4, image5, image6, image7];
  return (
    <>
      <GenericCarousel images={images} />
    </>
  );
};
export default CompaniesCarousel;
