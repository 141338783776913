import headerImage from "../media/mediaCompanies/companiesHeaderImage.png";
import icon1 from "../media/mediaCompanies/companiesHeaderIcon1.png";
import icon2 from "../media/mediaCompanies/companiesHeaderIcon2.png";
import icon3 from "../media/mediaCompanies/companiesHeaderIcon3.png";
import icon4 from "../media/mediaCompanies/icon4.png";
import icon5 from "../media/mediaCompanies/icon5.png";
import icon6 from "../media/mediaCompanies/icon6.png";
import panel5icon1 from "../media/mediaCompanies/panel5icon1.png";
import panel5icon2 from "../media/mediaCompanies/panel5icon2.png";
import panel5icon3 from "../media/mediaCompanies/panel5icon3.png";
import panel5icon4 from "../media/mediaCompanies/panel5icon4.png";

import CompaniesCarousel from "../components/CompaniesCarousel";
import "../style/companiesHomepage.css";
import CompaniesTestimonials from "../components/CompaniesTestimonials";
import Credits from "../components/Credits";
import PrimaryBtn from "../components/PrimaryBtn";

const CompaniesHomepage = () => {
  return (
    <>
      <div className="header">
        <div className="header-text">
          <h1>
            Fast-Track
            <br />
            Sustainable Innovation.
          </h1>
          <h3 id="companies-tagline">
            We make sustainable innovation easy and affordable by developing
            Innovation Sprints that engage stakeholders.
          </h3>

          <PrimaryBtn text="Get Started" navigateTo="/contact-us" />
        </div>
        <div className="header-image">
          <img src={headerImage} />
        </div>
      </div>

      <div className="panel2">
        <div className="panel2-group">
          <img src={icon1} />
          <p>
            <strong>
              Easily create and launch Sprints
              <br />
              internally or externally
            </strong>
          </p>
        </div>
        <div className="panel2-group">
          <img src={icon2} />
          <p>
            <strong>
              Discover engaging learning
              <br />
              materials & cutting-edge topics
            </strong>
          </p>
        </div>
        <div className="panel2-group">
          <img src={icon3} />
          <p>
            <strong>
              Facilitate open collaboration
              <br />
              with your team or end users
            </strong>
          </p>
        </div>
      </div>

      <div className="panel3">
        <CompaniesCarousel />
      </div>

      <div className="panel4">
        <div className="custom-shape-divider-top-1718802291">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <h1>Why should you launch a Sprint with Aula Future?</h1>
        <div className="panel4-groups">
          <div className="panel4-group">
            <img src={icon4} />
            <h2>Fast and scalable project ideation and development</h2>
            <p>
              We help foster collaboration between your internal teams and the
              public, leading to a wider range of creative solutions.
            </p>
          </div>

          <div className="panel4-group">
            <img src={icon5} />
            <h2>Upskill employees and access new talent</h2>
            <p>
              We enable employees to develop critical skills in sustainability
              while gaining access to a diverse pool of talent.
            </p>
          </div>

          <div className="panel4-group">
            <img src={icon6} />
            <h2>Mitigate risk through open-sourced R&D</h2>
            <p>
              By leveraging our platform, you gain access to a wider pool of
              knowledge, validate ideas faster, and minimize development costs.
            </p>
          </div>
        </div>
      </div>

      <div className="panel5">
        <h1>Helping you achieve measurable sustainability outcomes.</h1>
        <div className="panel5-groups">
          <div className="panel5-row">
            <img src={panel5icon1} />
            <div>
              <h2>Pinpoint Key Sustainability Goals</h2>
              <p>
                We collaborate with your team to identify the specific
                <br />
                goals and KPIs most critical to your company's
                <br />
                sustainability journey.
              </p>
            </div>
          </div>

          <div className="panel5-row">
            <img src={panel5icon2} />
            <div>
              <h2>Craft Custom Innovation Sprints</h2>
              <p>
                We help you design Innovation Sprints that engage
                <br />
                your internal teams, experts, and your stakeholders to
                <br />
                generate impactful solutions.
              </p>
            </div>
          </div>

          <div className="panel5-row">
            <img src={panel5icon3} />
            <div>
              <h2>Spark Innovation & Facilitate Collaboration</h2>
              <p>
                Spark creative problem-solving among your workforce
                <br />
                through our engaging learning resources and foster
                <br />
                collaboration between stakeholders.
              </p>
            </div>
          </div>

          <div className="panel5-row">
            <img src={panel5icon4} />
            <div>
              <h2>Measure Impact & Implement Solutions</h2>
              <p>
                We generate detailed reports outlining impact and potential
                <br />
                strategies for implementation, prioritizing promising ideas and
                <br />
                ensuring a smooth transition from concept to reality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="panel6">
        <CompaniesTestimonials />
      </div>

      <div className="panel7">
        <h1>Turn sustainability challenges into opportunities.</h1>

        <PrimaryBtn
          text="Schedule a free consultation"
          navigateTo="/contact-us"
        />
      </div>
      <div className="custom-shape-divider-top-1718803736-3">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="panel8">
        <Credits />
      </div>
    </>
  );
};
export default CompaniesHomepage;
