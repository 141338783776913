import "../style/contactPage.css";
import { useState } from "react";
import SubmitBtn from "./SubmitBtn";

const ContactUsForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <form className="contact-right-panel">
        <div className="contact-name">
          <div>
            <label>
              First name:
              <br />
            </label>{" "}
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>
              Last name:
              <br />
            </label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div>
          <label>
            Email (required)
            <br />
          </label>

          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div>
          <label>
            Message (required)
            <br />
            <textarea
              id="contact-message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </label>
        </div>

        <SubmitBtn text="Send" form={{ firstName, lastName, email, message }} />
      </form>
    </>
  );
};

export default ContactUsForm;
