import credits1 from "../media/credits1.png"
import credits2 from "../media/credits2.png";
import credits3 from "../media/credits3.png";
import credits4 from "../media/credits4.png";
import credits5 from "../media/credits5.jpg";
import credits6 from "../media/credits6.png";
import "../style/credits.css"

const Credits = () => {
  return (
    <>
      <div className="credits-container">
        <div className="credits-first-row">
          <img src={credits1} />
          <img src={credits2} />
          <img src={credits3} />
        </div>

        <p>
          <i>
            Das Vorhaben wird aus Mitteln der Europäischen Union (Europäischer
            Sozialfonds) und des Landes Berlin gefördert.
          </i>
        </p>

        <div className="credits-third-row">
          <img src={credits4} />
          <img src={credits5} id="europe-logo" />
          <img src={credits6} />
        </div>
      </div>
    </>
  );
};
export default Credits;
